import { SecondHeading, Section, TitleHighlight } from '@shared';
import styled from 'styled-components';
import { FINDING_THE_RIGHT_SCHOOL } from '../content';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

const Heading = styled(SecondHeading)`
  margin-top: 0;
  max-width: 100%;

  @media screen and (min-width: 800px) {
    max-width: 30%;
  }
`;

const Content = styled.div`
  max-width: 100%;

  @media screen and (min-width: 800px) {
    max-width: 60%;
  }
`;

export function FindingTheRightSchool() {
  return (
    <Section highlight paddingBottom="5rem" paddingTop="5rem">
      <Wrapper>
        <Heading>
          Why Finding the Right CNA School{' '}
          <TitleHighlight>Matters</TitleHighlight>
        </Heading>
        <Content>{FINDING_THE_RIGHT_SCHOOL}</Content>
      </Wrapper>
    </Section>
  );
}
