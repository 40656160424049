import { TitleHighlight } from '@shared';
import styled from 'styled-components';

const BenefitWrapper = styled.div`
  @media (min-width: 800px) {
    padding: 0 2rem;
    max-width: calc(100vw - 8rem);
  }
`;

const Title = styled.span`
  color: ${(props) => props.theme.navy.string()};
  font-size: 1.8rem;
  font-weight: ${(props) => props.theme.fontWeightLight};
`;

const Content = styled.div`
  margin-top: 3rem;
`;

interface BenefitProps {
  children: React.ReactNode;
  title: string;
}

export function Benefit({ children, title }: BenefitProps) {
  return (
    <BenefitWrapper>
      <TitleHighlight lineTop="1.6rem" ratioHeight="1.7rem">
        <Title>{title}</Title>
      </TitleHighlight>
      <Content>{children}</Content>
    </BenefitWrapper>
  );
}
