import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import styled from 'styled-components';

export const BACK_BTN_CLASS_NAME = 'benefits-back-btn';
export const FORWARD_BTN_CLASS_NAME = 'benefits-forward-btn';

const Button = styled.span`
  align-items: center;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.grey.fade(0.5).string()};
  border-radius: 50%;
  display: flex;
  font-size: 1.4rem;
  height: 50px;
  justify-content: center;
  transition: background-color 300ms;
  width: 50px;

  &:active,
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.grey.fade(0.9).string()};
    cursor: pointer;
    outline: none;
  }

  svg {
    max-width: 26px;
  }

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;

export function BackButton() {
  return (
    <Button className={BACK_BTN_CLASS_NAME}>
      <FaArrowLeft />
    </Button>
  );
}

export function ForwardButton() {
  return (
    <Button className={FORWARD_BTN_CLASS_NAME}>
      <FaArrowRight />
    </Button>
  );
}
