import { SecondHeading, Section } from '@shared';
import styled from 'styled-components';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  BENEFIT_CONTENT_1,
  BENEFIT_CONTENT_2,
  BENEFIT_CONTENT_3,
  BENEFIT_TITLE_1,
  BENEFIT_TITLE_2,
  BENEFIT_TITLE_3,
} from '../content';
import { Benefit } from './Benefit';
import {
  BackButton,
  BACK_BTN_CLASS_NAME,
  ForwardButton,
  FORWARD_BTN_CLASS_NAME,
} from './Nav';

const SwiperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
  order: 1;

  > span {
    margin: 0 10px;
  }

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
    margin: 0 2rem 0 0;
    order: 0;
  }
`;

const InnerSwiperWrapper = styled.div`
  flex-grow: 1;
  max-width: 100%;

  @media screen and (min-width: 800px) {
    max-width: 80%;
  }
`;

const Title = styled(SecondHeading)`
  text-align: left;

  @media screen and (min-width: 800px) {
    text-align: right;
  }
`;

/**
 * Swiper navigation config.
 * https://swiperjs.com/swiper-api#navigation
 */
const navigation = {
  nextEl: `.${FORWARD_BTN_CLASS_NAME}`,
  prevEl: `.${BACK_BTN_CLASS_NAME}`,
};

export function Benefits() {
  return (
    <Section>
      <Title>Reasons to Become a CNA</Title>
      <SwiperWrapper>
        <NavWrapper>
          <BackButton />
          <ForwardButton />
        </NavWrapper>
        <InnerSwiperWrapper>
          <Swiper
            autoplay
            loop
            modules={[Autoplay, Navigation]}
            navigation={navigation}>
            <SwiperSlide>
              <Benefit title={BENEFIT_TITLE_1}>{BENEFIT_CONTENT_1}</Benefit>
            </SwiperSlide>
            <SwiperSlide>
              <Benefit title={BENEFIT_TITLE_2}>{BENEFIT_CONTENT_2}</Benefit>
            </SwiperSlide>
            <SwiperSlide>
              <Benefit title={BENEFIT_TITLE_3}>{BENEFIT_CONTENT_3}</Benefit>
            </SwiperSlide>
          </Swiper>
        </InnerSwiperWrapper>
      </SwiperWrapper>
    </Section>
  );
}
