import { PageWrapper } from '@shared';
import {
  Benefits,
  FindingTheRightSchool,
  Search,
  StartYourSearch,
} from '../home';

export default function HomePage() {
  return (
    <PageWrapper>
      <Search />
      <StartYourSearch />
      <FindingTheRightSchool />
      <Benefits />
    </PageWrapper>
  );
}
