import { SchoolSearchFilters } from '@school';
import { Heading, Section } from '@shared';
import { useRouter } from 'next/router';
import { SchoolSearchInputRow } from 'src/school/search/SearchInputRow';
import styled from 'styled-components';
import { WelcomeImage } from './WelcomeImage';

const SearchWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  width: 100%;

  ${Heading} {
    margin: 1rem auto;
    text-align: center;
  }

  @media (min-width: 800px) {
    flex-wrap: wrap;
    max-height: 500px;

    ${Heading} {
      margin: 70px 0 0;
      text-align: left;
    }
  }
`;

const SearchFormWrapper = styled.div`
  order: 2;

  p {
    font-size: 1.2rem;
    text-align: center;
  }

  @media (min-width: 800px) {
    max-width: 600px;
    min-width: 300px;
    order: 1;
    width: 50%;

    p {
      text-align: left;
    }
  }
`;

const WelcomeImageWrapper = styled.div`
  flex-grow: 1;
  margin: auto 2rem 0.3rem;
  min-width: 300px;
  order: 1;
  position: relative;
  width: 100%;

  @media (min-width: 600px) {
    min-width: 400px;
  }

  @media (min-width: 700px) {
    min-width: 500px;
  }

  @media (min-width: 800px) {
    margin-top: 40px;
    max-width: 450px;
    min-width: 300px;
    order: 2;
    width: 40vw;
  }
`;

export function Search() {
  const router = useRouter();

  function handleSearch(query: string) {
    router.push(SchoolSearchFilters.create(query, {}).route);
  }

  return (
    <Section>
      <SearchWrapper>
        <Heading navy>CNA Classes For Me</Heading>

        <SearchFormWrapper>
          <p>
            Find the right CNA school for <em>you</em>.
          </p>
          <SchoolSearchInputRow onSubmit={handleSearch} />
        </SearchFormWrapper>

        <WelcomeImageWrapper>
          <WelcomeImage />
        </WelcomeImageWrapper>
      </SearchWrapper>
    </Section>
  );
}
