import homeTopRatedImg from '@assets/home-top-rated.jpg';
import homeNearYouImg from '@assets/home-near-you.jpg';
import homeOnlineSchoolsImg from '@assets/home-online-schools.jpg';
import { useUserLocation } from '@hooks';
import { SchoolSearchFilters } from '@school';
import { SecondHeading, Section, TitleHighlight } from '@shared';
import styled from 'styled-components';
import { DiscoverCard } from './DiscoverCard';

const Discover = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 2rem;
  width: 100%;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export function StartYourSearch() {
  const location = useUserLocation();
  const topRated = SchoolSearchFilters.create('', {});
  const online = SchoolSearchFilters.create('', { hasOnlineClasses: true });
  const nearYou = SchoolSearchFilters.forState(location?.state || '');

  return (
    <Section paddingBottom="3rem" paddingTop="1rem">
      <SecondHeading navy textAlign="center">
        Start Your Search <TitleHighlight>Now</TitleHighlight>
      </SecondHeading>
      <Discover>
        <DiscoverCard
          href={topRated.route}
          src={homeTopRatedImg}
          title="Top Rated Schools"
        />
        <DiscoverCard
          href={online.route}
          src={homeOnlineSchoolsImg}
          title="Online Schools"
        />
        <DiscoverCard
          href={nearYou.route}
          src={homeNearYouImg}
          title="Schools Near You"
        />
      </Discover>
    </Section>
  );
}
