export const BENEFIT_TITLE_1 = 'Growing Job Market';
export const BENEFIT_CONTENT_1 =
  'The CNA career field is projected to grow 34% from 2019-2029, which is much faster than the average of all jobs in the USA. Currently there are over 3,439,700 CNA & Personal Care Aide jobs in the workforce. CNA’s are essential workers and will always be needed.  Get trained and claim your job today!';
export const BENEFIT_TITLE_2 = 'Livable Wage';
export const BENEFIT_CONTENT_2 =
  'According to the Bureau of Labor Statistics, the median average salary for a CNA is $15 an hour depending on the state. In 2020, on average CNA’s made over $30,850 for the year. The current US minimum wage is $7.25 an hour. With this statistic, by just taking a 3-5 week course, you can more than double your income potential.';
export const BENEFIT_TITLE_3 = 'Fulfilling Career';
export const BENEFIT_CONTENT_3 =
  "I became a CNA because I wanted to help others in their time of need. Being able to be there for a person when they need help the most is such an honorable feeling. Being my patient's friend, listening to their stories, and advocating for their needs is my God given talent and I am happy to share it! —Victoria Randle";
export const FINDING_THE_RIGHT_SCHOOL =
  'Starting a career in the healthcare field is both rewarding and lucrative. However, finding a school that can meet your needs is where it all starts. The Secret Cocktail® is committed to helping you find a school that aligns with your special needs and busy schedule. If that means going to school online or twice a week at night, we are committed to helping you find a school that works for you! Browse our database of top ranked schools near you and find the perfect fit!';
