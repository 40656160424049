import Image, { StaticImageData } from 'next/legacy/image';
import Link from 'next/link';
import styled from 'styled-components';

interface DiscoverCardProps {
  href: string;
  src: StaticImageData;
  title: string;
}

const BackgroundWrapper = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Film = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Title = styled.span`
  bottom: 0.7rem;
  color: ${(props) => props.theme.white.string()};
  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeightBold};
  position: absolute;
  text-align: center;
  width: 100%;
`;

const DiscoverCardWrapper = styled.a`
  border-radius: 4px;
  box-shadow: 0 0 20px
    ${(props) => props.theme.textColor.mix(props.theme.white, 0.9).string()};
  display: inline-block;
  height: 260px;
  margin: 1rem;
  min-width: 300px;
  padding: 0;
  position: relative;
  text-decoration: none;
  width: 300px;

  ${BackgroundWrapper} {
    filter: grayscale(1);
    transition: filter 300ms;
  }

  ${Film} {
    background-color: ${(props) => props.theme.navy.fade(0.7).string()};
    transition: background-color 300ms;
  }

  &:hover {
    ${BackgroundWrapper} {
      filter: grayscale(0);
    }

    ${Film} {
      background-color: ${(props) => props.theme.navy.fade(0.9).string()};
    }
  }
`;

export function DiscoverCard({ src, href, title }: DiscoverCardProps) {
  return (
    <Link href={href} passHref legacyBehavior>
      <DiscoverCardWrapper>
        <BackgroundWrapper>
          <Image
            alt={title}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            priority
            src={src}
          />
        </BackgroundWrapper>
        <Film />
        <Title>{title}</Title>
      </DiscoverCardWrapper>
    </Link>
  );
}
