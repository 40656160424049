import welcomeImg from '@assets/home-welcome.jpg';
import { AspectRatio } from '@shared';
import Image from 'next/legacy/image';
import styled from 'styled-components';

const WelcomeImageOuterWrapper = styled.div`
  padding: 0 25px 25px 0;
`;

const aspectRatioProps = {
  // Maintain 80:55 aspect ratio (based on welcome image dimensions).
  ratio: '80:55',
};

const WelcomeImageInnerWrapper = styled(AspectRatio).attrs(aspectRatioProps)`
  margin-left: 25px;
  margin-top: 25px;
`;

const Decoration = styled(AspectRatio).attrs(aspectRatioProps)`
  background-color: ${(props) => props.theme.navy.string()};
  border-radius: 4px;
  left: -25px;
  position: absolute;
  top: -25px;
`;

const ImageWrapper = styled(AspectRatio).attrs(aspectRatioProps)`
  border-radius: 4px;
  bottom: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
`;

export function WelcomeImage() {
  return (
    <WelcomeImageOuterWrapper>
      <WelcomeImageInnerWrapper>
        <Decoration />
        <ImageWrapper>
          <Image
            alt=""
            layout="fill"
            placeholder="blur"
            priority
            src={welcomeImg}
          />
        </ImageWrapper>
      </WelcomeImageInnerWrapper>
    </WelcomeImageOuterWrapper>
  );
}
